
import { CgArrowRightO } from 'react-icons/cg';


import { TbCircleArrowUpRightFilled } from 'react-icons/tb';
import DAOStarter from './images/Links/DAOStarter.png'
import KDG from './images/Links/KDG.svg'
import Gagarin from './images/Links/Gagarin.svg'
import DAOLaunch from './images/Links/DAOLaunch.png'

import './Style.css'

function App() {
  return (
    <div className="App">

      <div class="manualconnect-modal-section">


        <div className='container'>

          <div className='row row-cols-lg-1 g-4 col-lg-5 min-vh-100 align-items-center mx-auto'>
            <div className='overall-box py-5'>
              <div className='d-flex text-center justify-content-center align-items-center mb-3'>
                <h4 className=''>
                  Join the IDO phase 2 at one of our Launchpad partners, stake your DCX and get upto 42% APY!</h4>
              </div>
              <div className='container pb-3'>
                    <div className='row row-cols-lg-1 g-4'>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://www.daostarter.pro/#/DAOStarter/index'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={DAOStarter} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>DAOstarter - July 3rd - July 7th</p>
                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://app.gagarin.world/ido/649c236693ecbba890e6e8d1'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={Gagarin} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Gagarin - July 10th - July 13th</p>

                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://kingdomstarter.io/launchpad/upcoming/d-ecosystem'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={KDG} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Kingdom Starter - July 14th - July 16th</p>
                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://daolaunch.notion.site/b0ab81647df84690980d93deaad13bfe?v=95a0950059d54f348455afc241baa712'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={DAOLaunch} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Dao Launch - July 17rd - July 20th</p>

                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      {/* <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://gagarin.world/'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={Gagarin} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Gagarin - TBD</p>

                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://daolaunch.net/'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={DAOLaunch} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>DAO Launch - TBD</p>
                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div> */}
                    </div>
                  </div>
              {/* <div className='col made-by-decosystem-banner-section '>
              <a target='_blank' href='https://gagarin.world/'>
                <div className='card d-flex flex-row justify-content-between align-items-center'>
                  <div >
                    <img className='buy-coin-links-logo-size' src={Gagarin} />
                  </div>
                  <div className=''>
                    <p className='mb-0'>Gagarin - TBD</p>

                  </div>
                  <CgArrowRightO className='buy-coin-links-icons-css' />
                </div>
              </a>
            </div>
            <div className='col made-by-decosystem-banner-section '>
              <a target='_blank' href='https://daolaunch.net/'>
                <div className='card d-flex flex-row justify-content-between align-items-center'>
                  <div >
                    <img className='buy-coin-links-logo-size' src={DAOLaunch} />
                  </div>
                  <div className=''>
                    <p className='mb-0'>DAO Launch - TBD</p>
                  </div>
                  <CgArrowRightO className='buy-coin-links-icons-css' />
                </div>
              </a>
            </div> */}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default App;
